<template>
  <div class="row">
    <div class="col-md-4">
      <div class="card card-custom gutter-b example example-compact">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">Basic Information</h3>
          </div>
        </div>
        <div class="card-body">
          <b-form v-if="show">
            <b-form-group id="input-group-0" label="Id:" label-for="input-0">
              <b-form-input
                id="input-0"
                v-model="study.id"
                disabled
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-1"
              label="Name:"
              label-for="input-1"
              description="The name of the clinical study"
            >
              <b-form-input
                id="input-1"
                v-model="study.name"
                :disabled="user.userTypeId != 4"
                required
                placeholder="Enter name"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-2"
              label="Description:"
              label-for="input-2"
              description="Description of the clinical study"
            >
              <b-form-textarea
                id="input-2"
                v-model="study.description"
                placeholder="Enter description"
                :disabled="user.userTypeId != 4"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>

            <b-form-group
              id="input-group-3"
              label="Study Status:"
              label-for="input-3"
            >
              <b-form-select
                id="input-3"
                v-model="study.status"
                :options="status_options"
                :disabled="user.userTypeId != 4"
                required
              ></b-form-select>
            </b-form-group>

            <b-button
              @click="onSubmit"
              variant="primary"
              v-if="user.userTypeId == 4"
              >Submit</b-button
            >
            <b-button
              @click="onDelete"
              variant="danger"
              v-if="user.userTypeId == 4"
              >Delete</b-button
            >
          </b-form>
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <div class="card card-custom gutter-b example example-compact">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">Groups</h3>
          </div>
          <div class="card-toolbar">
            <div class="justify-content-center">
              <b-button
                @click="onNewGroup"
                variant="info"
                v-if="user.userTypeId == 4"
                >New</b-button
              >
            </div>
          </div>
        </div>
        <div class="card-body">
          <b-table
            hover
            bordered
            v-if="study.groups"
            :items="study.groups"
            :fields="table_fields"
            @row-clicked="(item, index, event) => rowClickHandler(item)"
          ></b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "study",
  data() {
    return {
      status_options: [
        { text: "Select One", value: null },
        { text: "Active", value: true },
        { text: "Deactivated", value: false }
      ],
      show: true,
      study: {
        name: "",
        description: "",
        status: null
      },
      table_fields: [
        { key: "id", label: "Id" },
        { key: "name", label: "Name" },
        { key: "description", label: "Description" },
        { key: "createdAt", label: "Created At" }
      ]
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    }
  },
  watch: {
    $route() {
      this.refresh();
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Study" }]);
    this.refresh();
  },
  methods: {
    refresh() {
      ApiService.get("/studies/" + this.$route.params.id)
        .then(({ data }) => {
          this.study = data.data;
        });
        // .catch(({ error }) => {
        //   console.log(error);
        // });
    },
    onSubmit() {
      // evt.preventDefault();
      alert(JSON.stringify(this.form));
    },
    onDelete() {
      // evt.preventDefault();
      // Reset our form values
      this.form.name = "";
      this.form.description = "";
      this.form.status = null;
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    onNewGroup() {
      alert("This function is currently disabled.");
    },
    rowClickHandler(item) {
      if (this.$store.state.auth.user == 4) {
        this.$router.push("/group/" + item.id);
      } else {
        alert(
          "Current user does not have admin permission to access all group. Please use the side menu on the left to access restricted patient data."
        );
      }
    }
  }
};
</script>
